.accordion-container {
    width: auto;
    margin-top: 57px;
    min-height: 56rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    min-height: 800px;
}

.accordion-text {
    margin-left: 80px;
    color: var(--text-color);
    width: 466px;
    height: 100%;
}

.accordion-text h2 {
    font-family: Archivo Black;
    font-size: 6rem;
    font-weight: 400;
    line-height: 6rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 24px;
    margin-top: 0;
}

.accordion-text h2 span {
    color: var(--second-color);
}

.accordion-text p {
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.56rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 0;
}

.accordion-contentContainer {
    height: fit-content;
    width: 40%;
    min-width: 400px;
    color: var(--text-color);
    position: relative;
}

.accordion-itemContainer {
    transition: 0.2s ease-in-out;
}

.accordion-item-sideContainer {
    position: relative;
    border: 1px solid var(--text-color);
    text-align: center;
}

.accordion-sideItem-ButtonContainer {
    all: unset;
    border: 1px solid var(--text-color);
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.accordion-sideItem-ButtonContainer-verticalLine {
    width: 1px;
    height: 80%;
    background-color: var(--text-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s ease-in-out;
}

.accordion-sideItem-ButtonContainer-verticalLine-expanded {
    transform: rotateX(90deg);
    transform-origin: center;
}

.accordion-sideItem-ButtonContainer-horizontalLine {
    width: 80%;
    height: 1px;
    background-color: var(--text-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.accordion-item-extraContentContainer {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 5;
    transition: 0.2s ease-in-out;
    width: 100vw;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.accordion-item-extraContent-imgContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.accordion-item-extraContent-imgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.accordion-item-extraContent-textContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 80px;
    padding-right: 120px;
    box-sizing: border-box;
    background-color: var(--text-color);
    color: var(--main-color);
}

.accordion-itemContainer-expand {
    transform: translateX(calc(-100vw + 70px));
}

.accordion-phone-contentContainer {
    display: none;
}

@media (799px < width < 1025px) {
    .accordion-container {
        height: fit-content;
    }
    
    .accordion-container {
        flex-direction: column;
    }
    
    .accordion-contentContainer {
        margin-top: 100px;
        width: 600px;
        transform: translateX(calc(100vw - 600px));
        position: relative;
    }
    .accordion-container {
        margin-top: 58px;
    }

    .accordion-text h2 {
        font-size: 4rem;
        line-height: 4.2rem;
    }

    .accordion-contentContainer p {
        margin-bottom: 40px;
    }
}

@media (width < 800px) {
    .accordion-container {
        flex-direction: column;
        width: 100%;
    }

    .accordion-contentContainer {
        display: none;
    }

    .accordion-text {
        width: auto;
        margin-left: 16px;
        margin-right: 16px;
    }

    .accordion-text h2 {
        font-size: 2.5rem;
        line-height: 2.5rem;
        margin-bottom: 40px;
    }

    .accordion-text p {
        margin-bottom: 44px;
    }

    .accordion-phone-contentContainer {
        margin: auto 16px;
        display: block;
    }

}