@media (width > 1024px) {
    .hamburguerMenu {
        display: none;
    }
}
.hamburguerMenu {
    margin: auto;
}

.hamburguerMenu-button {
    width: 18px;
    height: 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background: none;
    border: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.hamburguerMenu-button-line {
    width: 100%;
    height: 1px;
    background-color: var(--text-color);
    opacity: 1;
    transition: 0.2s ease-in-out;
}

.hamburguerMenu-button-line-transform:nth-of-type(1) {
    transform-origin: left;
    transform: rotate(45deg) translateY(2px);
}

.hamburguerMenu-button-line-transform:nth-of-type(2) {
    opacity: 0;
}

.hamburguerMenu-button-line-transform:nth-of-type(3) {
    transform-origin: right;
    transform: rotate(-45deg) translateY(-9px);
}

.hamburguerMenu-links {
    height: fit-content;
    width: 100%;
    transition: 0.2s ease-in-out;
    z-index: 10;
    list-style: none;
    margin: 0;
    margin-top: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 28px;
    background-color: var(--main-color);
    padding-top: 28px;
    padding-bottom: 28px;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.hamburguerMenu-links li a {
    color: var(--text-color);
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 900;
    line-height: 1.53rem;
    letter-spacing: -0.02em;
    text-align: left;
    text-decoration: none;
}

.hamburguerMenu-links li .active {
    color: var(--second-color);
}

.hamburguerMenu-links-hidden {
    width: 0;
}