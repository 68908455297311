#loop-scroll-gallery {
    width: 100%;
    max-width: 519px;
    height: 973px;
    min-width: 250px;
    overflow: hidden;
}

.scroll-gallery-wrapper {
    width: 100%;
    height: 100%;
    margin: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
}

.scroll-gallery-wrapper-animation {
    animation: 1.5s linear 1 slidein;
}

.scroll-gallery-wrapper-animation-inverted {
    animation: 1.5s linear 1 slideinInverted;
}

.scroll-gallery-image-container {
    width: 100%;
    height: 100%;
}

.scroll-gallery-image-container img {
    width: 100%;
    height: 100%;
}

@keyframes slidein {
    0% {
        transform: translateY(120%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes slideinInverted {
    0% {
        transform: translateY(-120%);
    }
    100% {
        transform: translateY(0%);
    }
}

@media (799px < width < 1025px) {
    #loop-scroll-gallery {
        width: 464px;
        height: 845px;
    }
}