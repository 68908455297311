.about-frontPage-container {
    height: 100vh;
    min-height: 59rem;
    width: 100%;
    color: var(--text-color);
    z-index: 8;
    position: relative;
    background-color: black;
}

.about-frontPage-container img {
    width: 100%;
    object-fit: contain;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

.about-frontPage-container::before {
    content: "";
    width: 100%;
    height:15%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(15, 15, 15, 0), rgba(15, 15, 15, 1));
    z-index: 1;
}

.about-frontPage-content {
    height: 100%;
    margin: auto 80px;
    position: relative;
    display: flex;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 2fr;
    grid-template-areas:
        "title none"
        "par1 par2"
    ;
    gap: 40px;
}

.about-frontPage-title {
    font-family: "Archivo Black", sans-serif;
    font-size: 6rem;
    font-weight: 400;
    line-height: 6rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 0;
    grid-area: title;
}

.about-frontPage-title span {
    color: var(--second-color);
}

.about-frontPage-leftTextContainer {
    height: 100%;
    width: 100%;
    grid-area: par1;
    max-width: 900px;
}

.about-frontPage-rightTextContainer {
    height: 100%;
    width: 100%;
    grid-area: par2;
}

.about-frontPage-text {
    font-family: "Roboto", sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.575rem;
    letter-spacing: -0.02em;
    text-align: left;
}

.about-frontPage-text span {
    color: var(--second-color);
}

.about-frontPage-container-block {
    position: relative;
}

.about-frontPage-content-phone {
    display: none;
}

@media (940px < width < 1025px) {
    .about-frontPage-content {
        margin: auto 40px;
    }

    .about-frontPage-text {
        font-size: 1rem;
        line-height: 1.26rem;
    }

}

@media (width < 941px) {
/*     .about-frontPage-container {
        height: auto;
    } */

    .about-frontPage-content {
        display: none;
    }

    .about-frontPage-content-phone {
        margin: auto 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .about-frontPage-title-phone {
        font-family: "Archivo Black", sans-serif;
        font-size: 2.5rem;
        font-weight: 400;
        line-height: 2.5rem;
        letter-spacing: -0.02em;
        text-align: left;
        margin-bottom: 0;
        margin-top: 8rem;
    }

    .about-frontPage-title-phone span {
        color: var(--second-color)
    }

    .about-frontPage-text-phone {
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.26rem;
        letter-spacing: -0.02em;
        text-align: left;
        margin: 0;
    }

    .about-frontPage-text-phone span {
        color: var(--second-color)
    }

    .about-frontPage-text-phone:nth-of-type(1) {
        margin-top: 1.625rem;
    }

    .about-frontPage-text-phone:nth-of-type(2) {
        margin-top: 1rem;
    }

    .about-frontPage-text-phone:nth-of-type(3) {
        margin-top: 5.625rem;
        margin-bottom: 2rem;
    }
}