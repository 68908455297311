.navLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 41px;
    padding: 0;
    flex-wrap: wrap;
}

.navLinks li {
    font-family: "Roboto", sans-serif;
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 1.721rem;
    letter-spacing: -0.02em;
    text-align: left;
}

.navLinks a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.2rem;
    transition: 0.2s ease-in-out;
}

.navLinks a:not(.active):hover {
    color: var(--hover-gray);
}

.navLinks .active {
    color: var(--second-color);
}

@media (width < 1025px) {
    .navLinks {
        display: none;
    }
}