.careFuture-frontPage {
    position: relative;
    overflow: hidden;
    height: 90vh;
    min-height: 70rem;
}

.careFuture-bg {
    position: absolute;
    bottom: -153px;
    left: 0;
    z-index: -2;
    height: 153%;
    width: 126%;
    object-fit: cover;
}

.careFuture-container {
    width: auto;
    margin-left: 80px;
    margin-right: 80px;
    height: 100%;
    position: relative;
}

.careFuture-content {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 130px;
    color: var(--main-color);
    width: 50%;
}

.careFuture-title {
    font-family: Archivo Black;
    font-size: 6rem;
    font-weight: 400;
    line-height: 6rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 0;
    width: 100%;
}

.careFuture-title span {
    color: var(--second-color);
}

.careFuture-text {
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.85rem;
    letter-spacing: -0.02em;
    text-align: left;
    width: 80%;
    margin-bottom: 50px;
}
.careFuture-items {
    width: auto;
    height: 30rem;
    margin: 180px 80px;
}
.careFuture-items ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 20px;
}

.careFuture-items ul li {
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.careFuture-items ul li p {
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.85rem;
    letter-spacing: -0.02em;
    text-align: center;
}

@media (width < 450px) {
    .careFuture-title {
        font-size: 4rem;
    }
}

@media (799px < width < 1025px) {
    .careFuture-title {
        font-size: 4rem;
        line-height: 4rem;
    }
    .careFuture-text {
        font-size: 1.125rem;
        line-height: 1.721rem;
    }
    .careFuture-container {
        margin-left: 40px;
        margin-right: 40px;
    }
    .careFuture-items {
        margin-left: 40px;
        margin-right: 40px;
    }
    .careFuture-items ul li {
        width: 259px;
    }
    .careFuture-items ul li p {
        font-size: 1rem;
        line-height: 1.53rem;
    }
}

@media (width < 800px) {
    .careFuture-frontPage {
        min-height: 40rem;
    }
    .careFuture-container {
        margin-left: 16px;
        margin-right: 16px;
    }
    .careFuture-content {
        width: 100%;
    }
    .careFuture-title {
        font-size: 2.5rem;
        line-height: 2.5rem;
        width: 70%;
    }
    .careFuture-text {
        font-size: 1rem;
        line-height: 1.53rem;
        width: 70%;
    }
    .careFuture-bg {
        bottom: -132px;
        width: 186%;
    }

    .careFuture-items {
        margin-top: 45px;
        margin-left: 16px;
        margin-right: 16px;
    }
    .careFuture-items ul {
        gap: 60px;
    }
    .careFuture-items ul li {
        width: 259px;
    }
    .careFuture-items ul li p {
        font-size: 1rem;
        line-height: 1.53rem;
    }
}