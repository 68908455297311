.hotelSectionContainer {
    color: var(--text-color);
    position: relative;
    overflow: hidden;
}

.hotelSectionContainer-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

.hotelSectionContainer-bg::after {
    content: "";
    width: 100%;
    height: 20%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to top, rgba(15, 15, 15, 0), rgba(15, 15, 15, 1));
    z-index: 1;
}

.hotelSectionContainer-bg::before {
    content: "";
    width: 100%;
    height: 20%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(15, 15, 15, 0), rgba(15, 15, 15, 1));
    z-index: 1;
}

.hotelSectionContainer-bg img {
    filter: blur(2px) grayscale();
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hotelSectionContainer-content {
    padding-top: 120px;
    padding-bottom: 80px;
    padding-left: 80px;
    padding-right: 80px;
    box-sizing: border-box;
}

.hotelSectionContainer h2 {
    font-family: Archivo Black;
    font-size: 6rem;
    font-weight: 400;
    line-height: 6rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 24px;
    margin-top: 0;
}

.hotelSectionContainer h2 span {
    color: var(--second-color);
}

.hotelSection-vanContainer {
    width: 100%;
    height: 744px;
    position: relative;
    margin-top: 167px;
    color: var(--text-color);
}

.hotelSection-vanContainer h2{
    font-family: Archivo Black;
    font-size: 6rem;
    font-weight: 400;
    line-height: 6rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 24px;
    margin-top: 0;
    margin-left: 80px;
}

.hotelSection-vanContainer h2 span {
    color: var(--second-color);
}

.hotelSection-van-text {
    width: 400px;
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.56rem;
    letter-spacing: -0.02em;
    text-align: left;
    color: var(--text-color);
    position: absolute;
    bottom: 0;
    left: 40px;
}

.hotelSection-van-text p {
    margin: 0;
}

.hotelSection-van-yellowBoc {
    width: 400px;
    aspect-ratio: 1;
    position: absolute;
    top: 10rem;
    right: 40rem;
    background-color: var(--second-color);
}

.hotelSection-van-imgContainer {
    width: 749px;
    aspect-ratio: 1.5;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    right: 80px;
}

.hotelSection-van-imgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.2s ease-in-out;
}

.hotelSection-van-imgContainer img:hover {
    transform: scale(1.2);
}

.hotelSection-van-text-ImgContainer-phone {
    display: none;
}

@media (1200px < width < 1260px) {
    .hotelSection-van-yellowBoc {
        width: 400px;
        top: 12rem;
        right: 30rem;
    }
    .hotelSection-van-imgContainer {
        width: 600px;
        right: 80px;
        bottom: 0;
    }
}

@media ( 799px < width < 1200px) {
    .hotelSection-vanContainer h2{
        font-size: 4rem;
        line-height: 4.1rem;
    }

    .hotelSectionContainer {
        margin-top: 63px;
    }

    .hotelSectionContainer h2 {
        font-size: 4rem;
        line-height: 4.1rem;
    }

    .hotelSectionContainer li {
        margin-bottom: 17px;
    }

    .hotelSectionContainer-content {
        padding-top: 120px;
        padding-bottom: 80px;
        padding-left: 40px;
        padding-right: 40px;
        box-sizing: border-box;
    }
    .hotelSectionContainer-content {
        padding-left: 40px;
        padding-right: 40px;
    }
    .hotelSection-vanContainer {
        margin-top: 305px;
        height: 600px;
    }

    .hotelSection-van-yellowBoc {
        width: 317px;
        height: 317px;
        top: 150px;
        right: 25rem;
    }

    .hotelSection-van-imgContainer {
        width: 556px;
        right: 40px;
        bottom: 0;
    }

    .hotelSection-van-text {
        width: 374px;
        font-size: 1rem;
    }
}

@media (920px < width < 1020px) {
    .hotelSection-van-yellowBoc {
        width: 250px;
        height: 250px;
        top: 200px;
        right: 23rem;
    }

    .hotelSection-van-imgContainer {
        width: 450px;
        right: 40px;
        bottom: 0;
    }
}

@media (width < 921px) {
    .hotelSection-van-yellowBoc {
        width: 250px;
        height: 250px;
        top: 200px;
        right: 23rem;
    }

    .hotelSection-van-imgContainer {
        width: 360px;
        right: 40px;
        bottom: 0;
    }
    .hotelSection-vanContainer {
        margin-top: 98px;
    }

    .hotelSection-van-yellowBoc {
        display: none;
    }

    .hotelSection-van-imgContainer {
        display: none;
    }

    .hotelSection-van-text-ImgContainer-phone {
        display: block;
        position: relative;
        height: fit-content;
        width: 80%;
        aspect-ratio: 1.5;
        margin: auto;
    }

    .hotelSection-van-text {
        position: static;
        margin-left: 16px;
        margin-right: 16px;
        width: auto;
    }
    .hotelSection-van-text p{
        padding-top: 40px;
    }
    .hotelSection-van-yellowBoc-phone {
        width: 40%;
        aspect-ratio: 1;
        background-color: var(--second-color);
        position: absolute;
        left: 0;
        top: 0;
    }

    .hotelSection-van-imgContainer-phone {
        width: 80%;
        aspect-ratio: 1.5;
        position: absolute;
        left: 47px;
        top: 53px;
    }
    .hotelSection-van-imgContainer-phone img {
        width: 100%;
    }
    .hotelSection-vanContainer {
        height: auto;
    }
}

@media (width < 800px) {
    .hotelSection-vanContainer h2{
        font-size: 2.5rem;
        line-height: 2.6rem;
    }
    .hotelSectionContainer {
        margin-top: 63px;
    }

    .hotelSectionContainer h2 {
        font-size: 2.5rem;
        line-height: 2.6rem;
    }

    .hotelSectionContainer li {
        margin-bottom: 17px;
    }

    .hotelSectionContainer-content {
        padding-left: 16px;
        padding-right: 16px;
    }
}