.countrySelectionContainer {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    place-content: center;
    gap: 20px;
    margin-top: 120px;
    margin-bottom: 80px;
}

.countrySelectionContainer-spinner {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.countrySelectionContainer div {
    margin: auto;
    width: 100%;
    max-width: 500px;
    aspect-ratio: 1.60;
    overflow: hidden;
    border-radius: 20px;
    cursor: pointer;
}

.countrySelectionContainer div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}