.footer {
    color: var(--text-color);
    width: auto;
    margin: auto 80px;
}

.footer a {
    text-decoration: none;
    color: inherit;
    font: inherit;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    display: inline;
}

.footer-contactInfo p {
    margin: 0;
    padding: 0;
}

.footer-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.footer-title {
    text-align: center;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    margin: 0;

}

.footer-smIcons {
    display: flex;
    gap: 24px;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;
    height: 100%;
    margin: 0;
}

.footer-copyright {
    border-top: 2px solid var(--hover-gray);
    text-align: center;
    margin-top: 15px;
    height: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-copyright p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
}

@media (799px < width < 1025px) {
    .footer {
        margin: auto 40px;
    }

    .footer-title {
        font-size: 1rem;
    }

    .footer-copyright p {
        font-size: 10px;
        line-height: 12.1px;
    }
}

@media (width < 800px) {
    .footer {
        margin: auto 16px;
    }

    .footer-copyright {
        border: none;
    }

    .footer-content {
        grid-template-columns: 1fr 1fr 2fr;
        grid-template-columns: 1fr 1fr 1fr;
        /* Definir tres columnas en lugar de cuatro */
        grid-template-rows: auto auto;
        grid-template-areas:
            "title title title"
            /* Área para el título */
            "info . sm";
    }

    .footer-contactInfo {
        grid-area: info;
    }

    .footer-title-link {
        grid-area: title;
        border-bottom: 1px solid var(--text-color) !important;
        padding-bottom: 16px !important;
        margin-bottom: 16px !important;
    }

    .footer-title {
        font-size: 1rem;
        line-height: 1.172rem;
    }

    .footer-smIcons {
        grid-area: sm;
    }

    .footer-copyright {
        height: 50px;
    }
}