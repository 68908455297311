.SchedulesContainer {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 450px;
    height: max-content;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr;
    color: var(--text-color);
}
.SchedulesContainer div {
    height: 100%;
    border-left: 1px solid var(--hover-gray);
    border-right: 1px solid var(--hover-gray);
    margin-left: -1px;
}
.SchedulesContainer div:hover {
    background-color: var(--second-color);
    color: var(--main-color);
}

.SchedulesContainer h3 {
    font-family: Archivo Black;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 2.72rem;
    text-align: center;
    border-bottom: 1px solid var(--hover-gray);
    margin: 0;
    padding: 10px 0;
}
.SchedulesContainer div p:first-of-type {
    padding-top: 74px;
}
.SchedulesContainer p {
    height: 3.75rem;
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.56rem;
    text-align: center;
    margin: 0;
    margin-bottom: 50px;
    padding: 0;
}

@media (799px < width < 1025px) {
    .SchedulesContainer {
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 272px;
    }
    .SchedulesContainer h3 {
        font-size: 1.25rem;
        line-height: 1.36rem;
    }
    .SchedulesContainer p {
        height: 3rem;
        font-size: 1rem;
        line-height: 1.172rem;
        margin-bottom: 40px;
    }
    .SchedulesContainer div p:first-of-type {
        padding-top: 0;
        margin-top: 40px;
    }
}

@media (width < 800px) {
    .SchedulesContainer {
        display: none;
    }
}
