.tag-list {
    width: 100%;
    height: 485px;
    margin: auto;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
}


.tag-list-title {
    font-family: "Roboto", sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin-left: 80px;
    color: var(--text-color);
    position: absolute;
    top: 7%;
}

.loop-slider .inner {
    display: flex;
    width: fit-content;
    animation-name: loop;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: var(--direction);
    animation-duration: var(--duration);
}

.tag {
    height: 130px;
    display: flex;
    align-items: center;
    gap: 0 0.2rem;
    padding: 0.7rem 1rem;
    margin-right: 8rem;
}

.tag img {
    height: 100%;
}

@keyframes loop {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

@media (799px < width < 1025px) {
    .tag-list {
        height: 259px;
    }
    .tag-list-wrapper {
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tag-list-title {
        margin-left: 40px;
        top: 1%;
    }
    .tag {
        height: 105px;
        margin-right: 5rem;
    }
}

@media (width < 800px) {
    .tag-list {
        height: 140px;
    }
    .tag-list-title {
        margin: 0;
        text-align: center;
        width: 100%;
        top: 6px;
        font-size: 0.875rem;
        line-height: 0.875rem;
    }
    .tag {
        height: 50px;
        margin-right: 1rem;
    }
}