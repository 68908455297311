.bgGallery-container {
    width: auto;
    height: 100vh;
    min-height: 60rem;
    position: relative;
    overflow: hidden;
}

.bgGallery-bgContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

.bgGallery-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: 0.1s ease-in-out;
    opacity: 1;
}

.bgGallery-bgImg-off {
    opacity: 0 !important;
}

.bgGallery-bgContainer::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(15, 15, 15, 1) 80%);
    z-index: 1;
}

.bgGallery-contentContainer {
    width: auto;
    height: 100%;
    margin: auto 80px;
    position: relative;
    z-index: 2;
}

.bgGallery-contentAndButton {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.bgGallery-content {
    position: absolute;
    bottom: 200px;
    left: 0;
    color: var(--text-color);
    width: 100%;
    overflow: hidden;
}

.bgGallery-content h2 {
    font-family: Archivo Black;
    font-size: 6rem;
    font-weight: 400;
    line-height: 5rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 0;
    margin-bottom: 26px;
}

.bgGallery-content p {
    width: 50%;
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.563rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 0;
    margin-bottom: 26px;
}

.bgGallery-info {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

.bgGallery-info-animation-up {
    transform: translateY(-100%);
}

.bgGallery-imgButtonsContainer {
    position: absolute;
    bottom: 110px;
    right: 0;
    z-index: 2;
    width: 60%;
    height: 50%;
    overflow: hidden;
}

.bgGallery-imgButton0 {
    width: 380px;
    height: 450px;
    overflow: hidden;
    border: 4px solid var(--hover-gray);
    border-radius: 30px;
    position: absolute;
    bottom: 0;
    right: 100%;
}

.bgGallery-imgButton1 {
    width: 380px;
    height: 450px;
    overflow: hidden;
    border: 4px solid var(--hover-gray);
    border-radius: 30px;
    position: absolute;
    bottom: 0;
    right: 310px;
}

.bgGallery-transition {
    transition: 0.2s ease-in-out;
}

.bgGallery-imgButton2 {
    width: 292px;
    height: 346px;
    overflow: hidden;
    border-radius: 30px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.bgGallery-imgButton3 {
    width: 292px;
    height: 346px;
    overflow: hidden;
    border-radius: 30px;
    position: absolute;
    bottom: 0;
    right: -100%;
}

/* @media (width < 800px) {
    .bgGallery-content p {
        overflow-y: scroll;
    }
}
@media (width < 666px) {
    .bgGallery-content h2 {
        font-size: 4rem;
    }
} */

@media (799px < width < 1025px) {
    .bgGallery-contentContainer {
        margin: auto 40px;
    }

    .bgGallery-content p {
        font-size: 1rem;
    }

    .bgGallery-imgButton0 {
        width: 275px;
        height: 326px;
    }

    .bgGallery-imgButton1 {
        width: 275px;
        height: 326px;
        right: 232px;
    }

    .bgGallery-imgButton2 {
        width: 213px;
        height: 253px;
    }

    .bgGallery-imgButton3 {
        width: 213px;
        height: 253px;
    }

    .bgGallery-content h2 {
        font-size: 4rem;
        line-height: 4.2rem;
    }

    .bgGallery-content p {
        font-size: 1rem;
        line-height: 1.3rem;
        width: 70%;

    }
}

@media (width < 800px) {
    .bgGallery-contentContainer {
        margin: auto 16px;
    }
    .bgGallery-content h2 {
        font-size: 2.5rem;
        line-height: 2.6rem;
    }

    .bgGallery-content p {
        width: 100%;
        font-size: 1rem;
        line-height: 1.1rem;

    }

}

.bgGallery-soon {
    display: grid;
    place-content: center;
    height: 100vh;
    min-height: 800px;
    color: var(--text-color);
}