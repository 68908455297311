.doubleScrollGallery {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50vh;
    align-items: center;
}

.doubleScrollGallery-textContainer {
    margin-top: 50vh;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
}

.doubleScrollGallery-text {
    width: 230px;
    color: var(--text-color);
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.85rem;
    letter-spacing: -0.02em;
    text-align: center;
}

.doubleScrollGallery-text span {
    color: var(--second-color);
}

.doubleScrollGallery-galleryContainer {
    height: 100vh;
    width: auto;
    margin-left: 80px;
    margin-right: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
}

.doubleScrollGallery-galleryWrapper h3 {
    display: none;
    font-family: "Roboto", sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.913rem;
    letter-spacing: -0.02em;
    text-align: right;
    color: var(--text-color);
}

.doubleScrollGallery-titleTablet {
    display: none;
}

.doubleScrollGallery-galleriesTextContainer {
    color: var(--text-color);
    width: 33%;
    height: 475px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.doubleScrollGallery-galleriesTextContainer h2 {
    font-family: Roboto;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 2.8rem;
    letter-spacing: -0.02em;
    text-align: center;
}

.doubleScrollGallery-galleriesTextContainer ul {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
    margin: auto;
    height: 80px;
    gap: 20px;
}

.doubleScrollGallery-galleriesTextContainer li {
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: -0.02em;
    text-align: right;
    margin: auto;
}

.doubleScrollGallery-galleriesTextContainer div {
    height: 25%;
}

.doubleScrollGallery-videoContainer {
    height: 100vh;
    width: 100%;
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
    margin-bottom: -25%;
}

#home-finalVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.2s ease-in-out;
}

.doubleScrollGallery-finalDiv {
    height: 200vh;
    width: 100%;
    transform: translateY(-50%);
}

.doubleScrollGallery-opacity0 {
    opacity: 0;
    transition: 0.2s ease-in-out;
}

.doubleScrollGallery-opacity1 {
    opacity: 1;
    transition: 0.2s ease-in-out;
}

.doubleScrollGallery-scale {
    transform: scale(0.8);
}

@media (799px < width < 1025px) {
    .doubleScrollGallery-galleryContainer {
        margin-left: 40px;
        margin-right: 40px;
    }

    .doubleScrollGallery-galleriesTextContainer {
        display: none;
    }

    .doubleScrollGallery-titleTablet {
        display: block;
        position: absolute;
        top: 149px;
        width: 100%;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.913rem;
        letter-spacing: -0.02em;
        text-align: center;
        color: var(--text-color);
    }

    .doubleScrollGallery-galleryWrapper h3 {
        display: block;
        text-align: center;
        position: absolute;
        top: 1173px;
        width: 50%;
        margin: 0;

    }
}

@media (width < 800px) {
    .doubleScrollGallery-galleryWrapper {
        display: none;
    }

    .doubleScrollGallery-textContainer {
        margin-top: 100vh;
        height: 60vh;
    }

    .doubleScrollGallery-galleriesTextContainer {
        display: none;
    }
    .doubleScrollGallery-galleryContainer {
        height: 0;
    }
}