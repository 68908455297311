.teamGallery-container {
    margin-top: 275px;
    margin-left: 80px;
    margin-right: 80px;
    color: var(--text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 120px;
}


.teamGallery-topSeparator {
    top: -60px;
    bottom: auto;
}

.teamGallery-separator {
    height: 4px;
    width: 100%;
    background-color: var(--hover-gray);
    position: absolute;
    bottom: -60px;
    border-radius: 100%;
}

.teamGallery-cardContainer {
    width: 90%;
    margin: auto;
    position: relative;
}

.teamGallery-leftCardContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 2fr 1fr;
    grid-template-areas:
        "image name"
        "image desc"
        "image mail"
    ;
    gap: 16px;
}

.teamGallery-rightCardContainer {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 2fr 1fr;
    grid-template-areas:
        "name image"
        "desc image"
        "mail image"
    ;
    gap: 16px;
}

.teamGallery-leftCardContainer h3,
.teamGallery-rightCardContainer h3 {
    grid-area: name;
    display: flex;
    justify-content: center;
    align-items: center;
}

.teamGallery-leftCardContainer p,
.teamGallery-rightCardContainer p {
    grid-area: desc;
}

.teamGallery-leftCardContainer .teamGallery-mailContainer,
.teamGallery-rightCardContainer .teamGallery-mailContainer {
    grid-area: mail;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.teamGallery-mailContainer a {
    color: var(--text-color);
}

.teamGallery-imgContainer {
    grid-area: image;
    height: 100%;
    max-height: 380px;
    aspect-ratio: 0.8;
    margin: auto;
}

.teamGallery-imgContainer img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slideInRight {
    animation: slideInRight 0.5s ease-in-out;
}

.slideInLeft {
    animation: slideInLeft 0.5s ease-in-out;
}

.slideInUp {
    animation: slideInUp 0.5s ease-in-out;
}

.slideInDown {
    animation: slideInDown 0.5s ease-in-out;
}

.teamGallery-name {
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: -0.02em;
    text-align: left;
    color: var(--second-color);
    margin: 0;
    margin-bottom: 42px;
}

.teamGallery-desc,
.teamGallery-mailContainer a {
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 0;
}

.teamGallery-mailContainer img {
    width: 32px;
    height: 32px;
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInUp {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInDown {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@media (799px < width < 1200px) {
    .teamGallery-container {
        margin-top: 218px;
        margin-left: 40px;
        margin-right: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .teamGallery-name {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }

    .teamGallery-desc,
    .teamGallery-mailContainer a {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
}

@media (width < 800px) {
    .teamGallery-container {
        margin-top: 300px;
        margin-left: 16px;
        margin-right: 16px;
    }

    .teamGallery-leftCardContainer,
    .teamGallery-rightCardContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .teamGallery-imgContainer {
        max-height: 310px;
    }

    .teamGallery-name {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }

    .teamGallery-desc,
    .teamGallery-mailContainer a {
        font-size: 1rem;
        line-height: 1rem;
    }
}