.services-testimoniesSection {
    margin-top: 261px;
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 166px;
}

.services-testimoniesSection h2,
.yourExperienceContainer h2 {
    color: var(--text-color);
    font-family: Archivo Black;
    font-size: 6rem;
    font-weight: 400;
    line-height: 6rem;
    letter-spacing: -0.02em;
    text-align: center;
    margin-bottom: 80px;
}

.services-testimoniesSection h2 span,
.yourExperienceContainer h2 span {
    color: var(--second-color);
}

.services-testimoniesContainer {
    width: auto;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.yourExperienceContainer {
    color: var(--text-color);
    margin: 120px 80px;
}

.yourExperienceContainer ul {
    list-style: circle;
}

.yourExperienceContainer li {
    font-family: Roboto;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 16px;
}


.countrySelectionButtonContainer {
    position: absolute;
    z-index: 5;
    top: 100px;
    right: 80px;
}

.countrySelectionButtonContainer button {
    width: fit-content;
}

@media (799px < width < 1025px) {
    .services-testimoniesSection {
        margin-top: 136px;
        margin-left: 40px;
        margin-right: 40px;
    }

    .services-testimoniesContainer {
        gap: 12px;
    }

    .services-testimoniesSection h2,
    .yourExperienceContainer h2 {
        font-size: 4rem;
        line-height: 4rem;
    }

    .yourExperienceContainer {
        margin: 80px 40px;
    }

    .yourExperienceContainer li {
        font-size: 1.65rem;
        line-height: 2rem;
    }

    .countrySelectionButtonContainer {
        top: 25px;
    }
}

@media (width < 800px) {
    .services-testimoniesSection {
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 60px;
        margin-bottom: 80px;
    }

    .services-testimoniesSection h2,
    .yourExperienceContainer h2 {
        font-size: 2.5rem;
        line-height: 2.5rem;
        text-align: left;
        width: 18rem;
        margin-bottom: 40px;
    }

    .services-testimoniesContainer {
        width: 100%;
        flex-wrap: nowrap;
        overflow-x: scroll;
        justify-content: flex-start;
        padding-bottom: 10px;
    }

    .services-testimoniesContainer::-webkit-scrollbar {
        height: 6px;
    }

    .services-testimoniesContainer::-webkit-scrollbar-thumb {
        background-color: var(--second-color);
        border-radius: 6px;
    }

    .services-testimoniesContainer::-webkit-scrollbar-track {
        width: 100%;
        background-color: var(--main-color);
        border-radius: 6px;
    }

    .yourExperienceContainer {
        margin: 80px 16px;
    }

    .yourExperienceContainer li {
        font-size: 1.2rem;
        line-height: 1.4rem;
    }

    .countrySelectionButtonContainer {
        top: 25px;
        right: 50px;
    }
}