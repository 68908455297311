.testimonyContainer {
    width: 414px;
    height: 28.063rem;
    color: var(--text-color);
    border: 2px solid var(--text-color);
    border-radius: 10px;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.testimonyContainer h3 {
    font-family: Archivo Black;
    font-size: 24px;
    font-weight: 900;
    line-height: 29.05px;
    text-align: left;
    height: 3rem;
}

.testimony-desc {
    font-family: Roboto;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.31rem;
    text-align: left;
    overflow-y: scroll;
    height: 15rem;
}
.testimony-desc::-webkit-scrollbar {
    width: 6px;
}

.testimony-desc::-webkit-scrollbar-thumb {
    background-color: var(--text-color);
    border-radius: 6px;
}

.testimony-desc::-webkit-scrollbar-track {
    background-color: var(--main-color);
    border-radius: 6px;
}

.testimony-infoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.testimony-info-imgContainer {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
}

.testimony-info-imgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimony-info-textContainer {
    width: 200px;
}

.testimony-info-textContainer h4 {
    margin: 0;
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.56rem;
    text-align: left;
}

.testimony-info-textContainer p {
    font-family: Roboto;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.31rem;
    text-align: left;
}

@media (799px < width < 1025px) {
    .testimonyContainer {
        width: 307px;
        height: 22.813rem;
        padding: 20px 10px;
    }
    .testimony-info-imgContainer {
        width: 67px;
        height: 67px;
        border-radius: 50%;
        overflow: hidden;
    }
    .testimonyContainer h3 {
        font-size: 1.25rem;
        line-height: 1.513rem;
        margin: 0;
        margin-bottom: 9px;
        width: 100%;
    }
    .testimonyStarsContainer {
        width: 100%;
        text-align: left;
    }
    .testimony-desc {
        font-size: 1rem;
        line-height: 1.172rem;
        margin-top: 9px;
        margin-bottom: 9px;
    }
    .testimony-info-textContainer p {
        font-size: 1rem;
        line-height: 1.172rem;
    }
    .testimony-info-textContainer h4 {
        font-size: 1rem;
        line-height: 1.172rem;
    }
}

@media (width < 800px) {

}
