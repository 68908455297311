.home-frontPage {
    width: 100%;
    min-height: 43rem;
    height: 100vh;
    position: relative;
}

.home-frontPage-container {
    width: auto;
    height: -webkit-fill-available;
    margin: auto;
    position: relative;
    padding-right: 80px;
    padding-left: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 130px;
}

#fontPageVideo {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
}

.home-frontPage-content {
    color: var(--text-color);
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.home-frontPage-title {
    font-family: Archivo Black;
    font-size: 6rem;
    font-weight: 400;
    line-height: 6rem;
    letter-spacing: -0.02em;
    text-align: right;
    margin-bottom: 16px;
}

.home-frontPage-text {
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.85rem;
    letter-spacing: -0.02em;
    text-align: right;
    max-width: 460px;
    margin-bottom: 50px;
    margin-top: 0;
}

.home-frontPage-buttonContainer {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

@media (799px < width < 1025px) {
    .home-frontPage-container {
        padding-right: 40px;
        padding-left: 40px;
    }
    .home-frontPage-title {
        font-size: 4rem;
        line-height: 4rem;
    }
}

@media (width < 800px) {
    .home-frontPage-container {
        padding-right: 16px;
        padding-left: 16px;
        justify-content: center;
        align-items: center;
        margin: 0;
        height: 100%;
    }
    .home-frontPage-content {
        width: 100%;
        align-items: center;
    }
    .home-frontPage-title {
        font-size: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        width: 80%;
    }
    .home-frontPage-text {
        text-align: center;
        font-size: 1rem;
        width: 80%;
    }
    .home-frontPage-buttonContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}