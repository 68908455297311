.header {
    width: 100%;
    margin-top: 24px;
    position: absolute;
    top: 0;
    z-index: 10;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    height: inherit;
}

.title {
    font-family: 'Roboto', sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.721rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin-left: 80px;
}

.yellowColor {
    color: var(--second-color);
}

.title a {
    text-decoration: none;
    color: var(--text-color);
}

.navOptions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 41px;
    flex-wrap: wrap;
    margin-right: 80px;
}

@media (799px < width < 1025px) {
    .header {
        max-width: 1024px;
    }

    .title {
        margin-left: 40px;
    }

    .navOptions {
        margin-right: 40px;
    }
}

@media (width < 800px) {
    .title {
        margin-left: 16px;
    }

    .navOptions {
        margin-right: 16px;
    }
}