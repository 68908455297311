.timelapse-container {
    margin-top: 295px;
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 200px;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.timelapse-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 220px;
    box-sizing: border-box;
}

.timelapse-card-imgContainer {
    width: 80px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    background-color: var(--main-color);
}

.timelapse-card-imgContainer img {
    height: 100%;
    aspect-ratio: 1/1;
}

.timelapse-card h3 {
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.4rem;
    text-align: left;
    margin: 0;
    margin-top: 18px;
}

.yellowTitle {
    color: var(--second-color);
}

.timelapse-card p {
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.56rem;
    text-align: center;
    margin: 0;
    margin-top: 16px;
}

.timelapse-line {
    background-color: var(--second-color);
    height: 3px;
    position: absolute;
    top: 15%;
    z-index: 1;
}

.timelapse-line-animation {
    animation: growUp 5s linear;
    width: 100%;
}

@keyframes growUp {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@media (799px < width < 1025px) {
    .timelapse-container {
        margin-top: 129px;
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 129px;
    }

    .timelapse-card {
        width: 174px;
    }

    .timelapse-card-imgContainer {
        width: 54px;
        height: 40px;
    }

    .timelapse-card h3 {
        font-size: 1rem;
        line-height: 1.172rem;
    }

    .timelapse-card p {
        font-size: 1rem;
        line-height: 1.26rem;
    }

    .timelapse-line {
        top: 12%;
    }
}

@media (width < 800px) {
    .timelapse-container {
        margin-top: 116px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 80px;
        flex-direction: column;
        align-items: center;
        gap: 60px;
    }

    .timelapse-line {
        display: none;
    }

    .timelapse-card {
        width: 220px;
    }

    .timelapse-card-imgContainer {
        width: 54px;
        height: 40px;
    }

    .timelapse-card h3 {
        font-size: 1rem;
        line-height: 1.172rem;
        margin-top: 16px;
        color: var(--second-color);
    }

    .timelapse-card p {
        font-size: 1rem;
        line-height: 1.172rem;
        margin-top: 8px;
        height: fit-content;
    }
}